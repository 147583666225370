var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('reactive-base',{attrs:{"app":"witness_index,witness_time","credentials":_vm.user.user.es_username + ':' + _vm.user.user.es_password,"url":_vm.elasticUrl}},[_c('div',{staticClass:"col-lg-12 col-xl-12 order-lg-1 order-xl-1"},[_c('div',{staticClass:"widget"},[_c('div',{staticClass:"widget-header"},[_c('div',{staticClass:"widget-header-label"},[_c('h3',[_vm._v(_vm._s(_vm.title)+" "),_c('info-box',{attrs:{"field":_vm.infoBox}}),_c('small')],1)]),_c('div',{staticClass:"widget-header-toolbar"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableFilter),expression:"enableFilter"}],staticClass:"actions"},[_c('div',{staticClass:"filter-action filter-action-lg"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.victim-support-centre'),"componentId":"officeFilter","URLParams":true,"sortBy":"asc","size":100,"fuzziness":"AUTO","showSearch":true,"filterLabel":this.$t('labels.victim-support-centres'),"dataField":"office.raw","react":{
                  and: [
                    'SearchResult',
                    'userSubFilter',
                    'prioritySubFilter',
                    'ageSubFilter',
                    'riskFilter',
                    'categorySubFilter',
                    'crimeSubFilter',
                    'statusSubFilter',
                    'courtFilter',
                    'createdDateFilters'
                  ],
                }}})],1),_c('div',{staticClass:"filter-action filter-action-lg ml-3"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.court'),"componentId":"courtFilter","filterLabel":this.$t('labels.court'),"dataField":"court.raw","sortBy":"asc","URLParams":true,"showSearch":true,"react":{
                  and: [
                    'officeFilter',
                    'userSubFilter',
                    'prioritySubFilter',
                    'ageSubFilter',
                    'riskFilter',
                    'categorySubFilter',
                    'crimeSubFilter',
                    'statusSubFilter',
                    'createdDateFilters'
                  ],
                }}})],1),_c('div',{staticClass:"filter-action-sm ml-3"},[_c('ReactiveComponent',{attrs:{"componentId":"createdDateFilters","URLParams":true,"filterLabel":"created_at","react":{
                and: [
                  'officeFilter',
                  'userSubFilter',
                  'prioritySubFilter',
                  'ageSubFilter',
                  'riskFilter',
                  'categorySubFilter',
                  'crimeSubFilter',
                  'statusSubFilter',
                  'courtFilter'
                ],
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var aggregations = ref.aggregations;
              var setQuery = ref.setQuery;
              var value = ref.value;
return _c('div',{},[_c('date-picker',{attrs:{"opens":"left","placeholder":"Inlagt","label":"","dataField":"created_at","aggregations":aggregations,"setQuery":setQuery,"selectedValue":value,"show-dropdowns":true,"single-date-picker":"range"}})],1)}}])})],1),_c('div',{staticClass:"filter-action ml-3 filter-action"},[_c('multi-dropdown-list',{attrs:{"placeholder":this.$t('labels.administrator'),"componentId":"userSubFilter","sortBy":"asc","size":100,"dataField":"users.name.raw","nestedField":"users","filterLabel":this.$t('labels.administrator'),"URLParams":true,"react":{
                  and: [
                    'officeFilter',
                    'genderSubFilter',
                    'ageSubFilter',
                    'prioritySubFilter',
                    'riskFilter',
                    'categorySubFilter',
                    'crimeSubFilter',
                    'courtFilter',
                    'createdDateFilters' ],
                },"defaultQuery":function () { return ({
  aggs: {
    reactivesearch_nested: {
      nested: {
        path: 'users'
      },
      aggs: {
        'users.name.raw': {
          terms: {
            field: 'users.name.raw',
            size: 1000,
            order: {
              _key: 'asc'
            }
          }
        }
      }
    }
  }
}); }}})],1)])])]),_c('div',{staticClass:"widget-body no-padding"},[_c('selected-filters',{staticClass:"bb-1 curent-filters pl-4",attrs:{"clearAllLabel":this.$t('labels.clear')}})],1),_c('div',{staticClass:"widget-body no-padding"},[_c('reactive-component',{attrs:{"componentId":"myColorPicker","react":{
          and: [
            'riskPlaceholder',
            'officeFilter',
            'prioritySubFilter',
            'genderSubFilter',
            'ageSubFilter',
            'prioritySubFilter',
            'riskFilter',
            'categorySubFilter',
            'userSubFilter',
            'crimeSubFilter',
            'statusSubFilter',
            'courtFilter',
            'createdDateFilters'
          ],
        },"defaultQuery":function () { return ({
  aggs: {
    users: {
      nested: {
        path: 'users'
      },
      aggs: {
        name: {
          terms: {
            field: 'users.name.raw',
            size: 1000,
            order: {
              _key: 'asc'
            }
          },
          aggs: {
            supporter_status_tag: {
              terms: {
                field: 'users.supporter_status_tag.raw',
                size: 1,
                min_doc_count: 0,
              },
            },
            all_times: {
              reverse_nested: {},
              aggs: {
                times: {
                  nested: {
                    path: 'times'
                  },
                  aggs: {
                    type: {
                      terms: {
                        field: 'times.label.raw'
                      },
                      aggs: {
                        time: {
                          sum: {
                            field: 'times.time'
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    time_users: {
      nested: {
        path: 'time_users'
      },
      aggs: {
        name: {
          terms: {
            field: 'time_users.name.raw',
            size: 1000,
            order: {
              _key: 'asc'
            }
          },
          aggs: {
            all_times: {
              reverse_nested: {},
              aggs: {
                times: {
                  nested: {
                    path: 'times'
                  },
                  aggs: {
                    type: {
                      terms: {
                        field: 'times.label.raw'
                      },
                      aggs: {
                        time: {
                          sum: {
                            field: 'times.time'
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    persons_count: {
      nested: {
        path: 'persons'
      },
      aggs: {
        name: {
          terms: {
            field: 'persons.user.keyword',
            size: 1000,
            order: {
              _key: 'desc'
            }
          }
        }
      }
    },
    testimonies: {
      filter: {
        term: {
          _index: 'witness_index'
        }
      },
      aggs: {
        users: {

          terms: {
            field: 'testimony_users.name.keyword',
            size: 1000,
            order: {
              _key: 'asc'
            }
          }
        }
      }
    }


  }
}); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var aggregations = ref.aggregations;
var hits = ref.hits;
var setQuery = ref.setQuery;
var loading = ref.loading;
return _c('div',{},[_c('div',{staticClass:"row"},[(!loading)?_c('div',{staticClass:"col-12"},_vm._l((_vm.getAdministrators(aggregations)),function(item){return _c('table',{key:item.key,staticClass:"vuetable",staticStyle:{"table-layout":"fixed"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"3%"}},[(item.supporter_status_tag.buckets[0] && item.supporter_status_tag.buckets[0].doc_count > 0)?_c('status-icon',{staticStyle:{"position":"absolute","left":"20px"},attrs:{"supporterStatusTag":item.supporter_status_tag.buckets[0].key}}):_vm._e(),_vm._v(" "+_vm._s(item.key)+" ")],1),_c('th',{attrs:{"width":"2%"}},[_vm._v(" Antal mål ")]),_c('th',{attrs:{"width":"2%"}},[_vm._v(" Stödsökande ")]),_c('th',{attrs:{"width":"2%"}},[_vm._v(" Antal pass ")]),_vm._l((_vm.testimonialTimeTypes),function(type){return _c('th',{key:type.id,attrs:{"width":"2%"}},[_vm._v(" "+_vm._s(type.label)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"padding-left":"20px"}}),_c('td',[_vm._v(_vm._s("testimony_count" in item ? item.testimony_count : 0))]),_c('td',[_vm._v(_vm._s(item.persons_count ? item.persons_count.doc_count : 0))]),_c('td',[_vm._v(_vm._s(item.time_count))]),_vm._l((_vm.testimonialTimeTypes),function(type){return _c('td',{key:item.key + type.id},[_vm._v(" "+_vm._s(_vm.getNumberOfTime(type.label, item))+" ")])})],2)])])}),0):_vm._e()])])}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }