<template>
  <layout-main>
    <div class="survey">
      <div class="subheader">
        <div class="subheader-main">
          <h3>Nytt mål</h3>
          <span class="separator-line"></span>
          <div class="breadcrumbs">
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/" class="breadcrumbs-link"> Mål</router-link>
            <span class="separator-dot"></span>
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.overview') }}</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xl-12">
          <div v-if="currentStep === 3">
            <div class="text-center text-muted pt-5">
              <h5>
                <i class="fas fa-circle-notch fa-spin"></i>
                <div class="mt-4">Skapar ditt mål..</div>
              </h5>
            </div>
          </div>

          <div v-if="currentStep === 1">
            <div class="row">

              <div class="col-3">
                <div class="widget">
                  <div class="widget-header">
                    <div class="widget-header-label">
                      <h3>
                        Skapa nytt mål
                      </h3>
                    </div>
                  </div>
                  <div class="widget-body">
                    <div class="form-group">
                       <label>Domstol: <span class="text-danger">*</span></label>
                      <!-- <input type="text" v-model="newTicket.person.first_name" class="form-control" placeholder=""/> -->

                      <select-field
                        api=""
                        :clearable="false"
                        :model="newTestimony"
                        :multiple="false"
                        :options="testimonyFieldOptions.courts"
                        db_field=""
                        field="court"
                        label=""
                        :optionLabel="$label()"
                      ></select-field>

                    </div>
                    <!-- <div class="form-group">
                      <label>{{ $t('labels.last-name') }}:</label>

                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.email') }}:</label>

                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.phone') }}:</label>

                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.mobile') }}:</label>

                    </div> -->
                  </div>
                  <div class="widget-footer pt-3 pb-3 text-right">

                    <!-- <v-guard :permissions="['ticket-access-office', 'ticket-access-all-tickets']"> -->
                      <button @click="createTestimony" class="btn btn-success"
                              :disabled="Object.keys(newTestimony.court).length === 0">
                        Skapa mål
                      </button>
                    <!-- </v-guard> -->

                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import BojAPI from '@/api/boj';
import 'vue-select/dist/vue-select.css';
import NewTicketOfficeFilter from '@/components/ticket/NewTicketOfficeFilter';
import SelectField from '@/components/ticket/fields/SelectField';
import moment from 'moment';

export default {
  name: 'ticket-page',
  components: {
    LayoutMain,
    NewTicketOfficeFilter,
    SelectField
  },
  props: {
    id: Number,
  },
  data() {
    return {
      newTestimony: {
        court: {},
      },
      responseTestimony: {},


      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      isSavingTicket: true,
      getSortByOrder: true,
      getSortBy: '_score',
      currentStep: 1,
      moment: moment,
      steps: [
        {
          id: 1,
          label: 'Person',
          completed: false,
        },
        {
          id: 2,
          label: 'Förmedling',
          completed: false,
        },
      ],

      showLeaveConfirmation: false,

      testimonyFieldOptions: {},
      isLoadingOptions: false,

      newTicket: {
        person: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          mobile: '',
        },
        priority_id: {id: 2},
        local_office_id: {},
      },
      responseTicket: {},
      ticket: {
        call_duration: 0,
        information: '',
        gender: '',
      },
      selected_person: false,
      hide: [],
      facets: [
        {
          level: 1,
          visible: true,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centres'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'municipalityFilter',
          field: 'municipality.raw',
          label: 'Kommun',
          placeholder: 'Kommun',
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          id: 'search',
          visible: true,
          field: 'person_name',
          label: 'Sök',
          placeholder: `${this.$t('labels.name')}..`,
          type: 'search',
          permission: null,
        },
        {
          level: 1,
          id: 'phoneFilter',
          visible: true,
          field: ['person_phone', 'person_phone_work', 'person_mobile'],
          label: 'Telefon #',
          placeholder: 'Telefon #..',
          type: 'search',
          permission: 'ticket-search-view-phone',
        },
        {
          level: 1,
          id: 'searchID',
          visible: true,
          field: 'id_searchable',
          label: 'Ärende #',
          placeholder: 'Ärende #..',
          type: 'search',
          permission: null,
        },
      ]
    };
  },
  mounted() {
    this.getTestimonyOptions();
  },
  methods: {
    getTestimonyOptions() {
      this.isLoadingOptions = true

      BojAPI.getTestimonyOptions().then((response) => {
        this.testimonyFieldOptions = response.data;
        this.isLoadingOptions = false

        if (this.testimonyFieldOptions.courts.length == 1) {
          this.newTestimony.court = this.testimonyFieldOptions.courts.find(e => true)

          this.createTestimony()
        }
      });
    },

    createTestimony() {
      this.currentStep = 3;

      BojAPI.createTestimony(this.newTestimony)
        .then((response) => {
          this.responseTestimony = response.data;

          this.$router.push({
            name: 'case',
            params: {id: this.responseTestimony.id},
          });
        })
        .catch((error) => {
          /**
           *
           * TODO: Check if user has a member
           *
           **/

          this.$notify({
            group: 'foo',
            text: 'Något gick snett',
            type: 'warn',
            duration: 3000,
          });
        })
    },

    /**
     * Returns facets given by their level value.
     *
     * @param level
     * @returns {({visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, nestedField: string, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string})[]}
     */
    getFacetsByLevel(level) {
      let filters = this.facets.filter((a) => a.level === level);
      let hiddenFields = this.hide;
      filters = filters.filter(function (el) {
        return !hiddenFields.includes(el.field);
      });
      return filters;
    },

    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

     /**
     * Add filter ids not present in facets.
     *
     * @param id
     * @returns {*[]}
     */
    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id !== id).map((a) => a.id);
      ids.push('DataControllerSensor');
      ids.push('riskFilter');
      ids.push('search');

      return ids;
    },
    setLocalOfficeAndCreateTicket(id) {
      this.newTicket.local_office_id = {id: id};
      this.nextStep();
      this.nextStep();
    },

    setLocalOffice(id) {
      this.newTicket.local_office_id = {id: id};
      this.nextStep();
    },
    pageChange() {
    },
    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
    },
    previousStep() {
      this.currentStep = this.currentStep - 1;
      this.showLeaveConfirmation = false;
    },
    nextStep() {
      this.currentStep = this.currentStep + 1;

      if (this.currentStep === 3) {
        this.showLeaveConfirmation = false;

        BojAPI.saveTicket(this.newTicket).then((response) => {
          this.responseTicket = response.data;
          this.$router.push({
            name: 'ticket',
            params: {id: this.responseTicket.id},
          });
        });
      } else {
        this.showLeaveConfirmation = true;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.showLeaveConfirmation) {
      const answer = window.confirm(this.$t('labels.close-ticket-without-saving'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    defaultQuery() {
      if (this.$store.getters.permissions.includes('ticket-access-office')) {
        return {
          query: {
            bool: {
              must: [
                {
                  match: {
                    'office.raw': this.user.user.member.local_office.office.label,
                  },
                },
              ],
            },
          },
        };
      } else {
        return {
          query: {
            bool: {
              'must_not': [
                {
                  match: {
                    'office.raw': 'Placeholder',
                  },
                },
              ],
            },
          },
        };
      }
    },
  },
};
</script>
