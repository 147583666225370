<template>
	<layout-main>
		<div class="home">
			<!-- Header -->
			<div class="subheader">
				<div class="subheader-main">
					<h3>
						Statistik D
					</h3>
					<span class="separator-line"></span>
					<div class="breadcrumbs">
						<router-link to="/cases" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
						<span class="separator-dot"></span>
						<router-link to="/cases/statistics" class="breadcrumbs-link"> Statistik D</router-link>
					</div>
				</div>
			</div>
			<!-- Header -->


			<witness-list
				:enable-filter="$store.getters.permissions.includes('court-page-active-use-filter')"
				ref="witnesslist"
				:default-query="query"
				title="Statistik"
			></witness-list>


			<ScrollTopArrow />
		</div>
	</layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import WitnessList from '@/components/lists/witness/WitnessStatisticsList.vue';
import ScrollTopArrow from '@/components/ScrollToTop'

export default {
	components: {
		LayoutMain,
		WitnessList,
		ScrollTopArrow
	},
	data() {
		return {
		query: {}
		}
	}
};
</script>


<style>
.level-1 > th {
  padding-left: 1em;
}
</style>
