<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              Nedlagd tid
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/cases" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/cases" class="breadcrumbs-link"> Nedlagd tid</router-link>
            </div>
          </div>
          <div class="subheader-right">
            <v-guard :permissions="['court-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> Exportera</template>

                <b-dropdown-group id="dropdown-group-1" header="Excel">
                  <b-dropdown-item-button @click="$refs.witnesslist.exportResultsEstimate('witness', 'excel')"> Nedlagd tid
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
              <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> Vänta
              </button>
            </v-guard>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <witness-time-list
            :enable-filter="$store.getters.permissions.includes('court-page-active-use-filter')"
            ref="witnesslist"
            :default-query="query"
            :testimonialTimeTypes="testimonialTimeTypes"
            title="Domstol"
        ></witness-time-list>
      </div>

    </div>
  </layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import LayoutMain from '@/views/Layouts/Main';
import Notifications from '@/components/dashboard/Notifications.vue';
import WitnessTimeList from '@/components/lists/witness/WitnessTimeList.vue';

export default {
  name: 'home',
  components: {
    LayoutMain,
    Notifications,
    WitnessTimeList,
  },
  data() {
    return {
      isDownloading: false,
      testimonialTimeTypes: [],
      query: {},
    };
  },
  beforeRouteEnter (to, from, next) {
    BojAPI.getTestimonialTimeTypes().then((response) => {
      next((vm) => {
        vm.testimonialTimeTypes = response.data;
      });
    });
  },
};
</script>
