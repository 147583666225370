<template>
  <reactive-base
      v-if="user"
      app="witness_time"
      :credentials="user.user.es_username + ':' + user.user.es_password"
      :url="elasticUrl"
  >

    <export-witness-time @beginWitnessExport="exportResults" @closed="closeExportWitness" :exportEstimate="exportEstimate"
      v-if="exportEstimate">
    </export-witness-time>

    <state-provider v-if="stats">
      <div class="col-12" :includeKeys="['value', 'hits', 'aggregations', 'error']" slot-scope="{ searchState }">
        <div class="row">

          <div class="col-md-8" v-if="searchState">
            <div class="widget">
              <div class="widget-header compact">
                <div class="widget-header-label">
                  <h3>
                    Information
                  </h3>
                </div>
              </div>

              <div class="widget-body pt-3 pb-4">
                <div class="row">
                  <div class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ getBucketCount(searchState, 'DataControllerSensor', 'times', 'Restid', 'label') ? getBucketCount(searchState, 'DataControllerSensor', 'times', 'Restid', 'label').doc_count.toLocaleString('sv-SE') : 0 }}
                    </div>
                    <div class="label label-red">
                      Antal pass
                    </div>
                  </div>
                  <div v-for="type in testimonialTimeTypes" :key="type.id" class="col text-center br-1">
                    <div class="text-big text-big-xl">
                      {{ minutes_to_hhmm(getBucketCount(searchState, 'DataControllerSensor', 'times', type.label, 'label') ? getBucketCount(searchState, 'DataControllerSensor', 'times', type.label, 'label').time.value : 0) }}
                    </div>
                    <div class="label" :style="{ color: type.color, backgroundColor: type.color + '1A' }">
                      {{ type.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </state-provider>

    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">
        <div class="widget-header">
          <div class="widget-header-label">
            <h3>
              Pass
            </h3>
          </div>

          <div class="widget-header-toolbar">
            <div class="actions" v-show="enableFilter">
							<span v-for="facet in getFacetsByLevel(1)" :key="facet.id">
								<div
                    :class="{
										'filter-action-sm': facet.small,
										'filter-action': facet.type === 'list',
										'search-action': facet.type === 'search',
									}"
                    class="ml-3"
                    v-if="checkFacetPermission(facet.permission)"
                >
									<multi-dropdown-list
                      v-if="facet.type === 'list'"
                      :placeholder="facet.placeholder"
                      :componentId="facet.id"
                      :URLParams="true"
                      sortBy="asc"
                      :size="300"
                      :nestedField="facet.nestedField"
                      fuzziness="AUTO"
                      searchPlaceholder="Sök..."
                      :showSearch="true"
                      :filterLabel="facet.label"
                      :dataField="facet.field"
                      :react="{ and: getOtherFilterIds(facet.id) }"
                  />

									<data-search
                      v-if="facet.field === 'person_name'"
                      componentId="search"
                      dataField="person_name"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="$t('labels.name')+'..'"
                      :debounce="100"
                      :autosuggest="false"
                      :filterLabel="$t('labels.search')"
                  />

									<data-search
                      v-if="facet.type === 'search' && facet.field !== 'person_name'"
                      :componentId="facet.id"
                      :dataField="facet.field"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :placeholder="facet.placeholder"
                      :debounce="100"
                      queryFormat="and"
                      :autosuggest="false"
                      :filterLabel="facet.label"
                  />


                  <ReactiveComponent
                    v-if="facet.type === 'custom-datepicker'"
                    :componentId="facet.id"
                    :URLParams="true"
                    :filterLabel="facet.label"
                    :react="{ and: getOtherFilterIds(facet.id) }"
                  >
                    <div slot-scope="{ aggregations, setQuery, value }">
                      <date-picker
                        opens="left"
                        :placeholder="facet.placeholder"
                        :dataField="facet.field"
                        :aggregations="aggregations"
                        :setQuery="setQuery"
                        :selectedValue="value"
                        :show-dropdowns="facet.showDropdowns"
                        :single-date-picker="facet.singleDatePicker"
                      ></date-picker>
                    </div>
                  </ReactiveComponent>

								</div>
							</span>

              <!-- <toggle-button
                  class="filter-action filter-action-xs ml-3 risk-toggle"
                  componentId="riskFilter"
                  :dataField="$field('risk')+'.raw'"
                  :URLParams="true"
                  :data="[{ label: '', value: $i18n.locale == 'en' ? 'Risk' : 'Förhöjd risk' }]"

              /> -->
              <!-- <div class="btn btn-grey btn-bold ml-3" @click="toggleFilter" v-show="!showFilter"><i
                  class="fas fa-filter"></i></div>
              <div class="btn btn-success btn-bold ml-3" @click="toggleFilter" v-show="showFilter"><i
                  class="fas fa-filter"></i></div> -->


            </div>
          </div>
        </div>

        <div class="widget-filter bb-1" v-show="showFilter && enableFilter">
          <div class="row search-filters">
            <div class="col-2 mb-4" v-show="facet.visible" v-for="facet in getFacetsByLevel(2)" :key="facet.id">

              <multi-dropdown-list
                  v-if="facet.type === 'list' && facet.id === 'ageSubFilter'"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :URLParams="true"
                  sortBy="asc"
                  :nestedField="facet.nestedField"
                  fuzziness="AUTO"
                  :showSearch="true"
                  :size="100"
                  :title="facet.label"
                  :filterLabel="facet.label"
                  :dataField="facet.field"
                  :transformData="customOrder"
                  :react="{ and: getOtherFilterIds(facet.id) }"
              />

              <multi-dropdown-list
                  v-else-if="facet.type === 'list'"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :URLParams="true"
                  sortBy="asc"
                  :nestedField="facet.nestedField"
                  fuzziness="AUTO"
                  :size="100"
                  :showSearch="true"
                  :title="facet.label"
                  :filterLabel="facet.label"
                  :dataField="facet.field"
                  :react="{ and: getOtherFilterIds(facet.id) }"
              />

              <data-search
                  v-if="facet.type === 'search'"
                  :dataField="[facet.field]"
                  iconPosition="right"
                  className="data-search"
                  :showClear="true"
                  :URLParams="true"
                  fuzziness="2"
                  :filterLabel="facet.label"
                  :placeholder="facet.placeholder"
                  :componentId="facet.id"
                  :debounce="100"
              />

              <ReactiveComponent
                v-if="facet.type === 'custom-datepicker'"
                :componentId="facet.id"
                :URLParams="true"
                :filterLabel="facet.label"
                :react="{ and: getOtherFilterIds(facet.id) }"
              >
                <div slot-scope="{ aggregations, setQuery, value }">
                  <date-picker
                    opens="left"
                    :placeholder="facet.placeholder"
                    :label="facet.label"
                    :dataField="facet.field"
                    :aggregations="aggregations"
                    :setQuery="setQuery"
                    :selectedValue="value"
                    :show-dropdowns="facet.showDropdowns"
                    :single-date-picker="facet.singleDatePicker"
                  ></date-picker>
                </div>
              </ReactiveComponent>

            </div>
          </div>
        </div>
        <div class="widget-body no-padding">
          <SelectedFilters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
        </div>
        <div class="widget-body no-padding">
          <ReactiveComponent componentId="DataControllerSensor" :defaultQuery="customTimesAggregation" :react="{ and: getOtherFilterIds('foo') }"></ReactiveComponent>

          <reactive-list
              @queryChange="getQueryChange"
              @pageChange="pageChange"
              :showResultStats="false"
              componentId="SearchResult"
              :dataField="getSortBy"
              :sortBy="getSortByOrder ? 'desc' : 'asc'"
              className="result-list-container"
              :pagination="true"
              :URLParams="true"
              :from="0"
              :size="15"
              :react="{ and: getOtherFilterIds('foo') }"
            >
            <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data, resultStats }">

              <thead v-if="data.length !== 0">
              <tr>
                <th
                    width="1%"
                    :class="{ 'sort-up': isSortField('id', true), 'sort-down': isSortField('id', false) }"
                    @click="changeSortBy('id')"
                >
                  #
                </th>
                <th
                    width="2%"
                    :class="{ 'sort-up': isSortField('created_at', true), 'sort-down': isSortField('created_at', false) }"
                    @click="changeSortBy('created_at')"
                >
                  Inlagt
                </th>
                 <th
                    width="2%"
                    :class="{ 'sort-up': isSortField('updated_at', true), 'sort-down': isSortField('updated_at', false) }"
                    @click="changeSortBy('updated_at')"
                >
                  {{ $t('labels.updated') }}
                </th>
                <th
                    width="2%"
                    :class="{ 'sort-up': isSortField('court.raw', true), 'sort-down': isSortField('court.raw', false) }"
                    @click="changeSortBy('court.raw')"
                >
                  <!-- {{ $t('labels.priority') }} -->
                  Domstol
                </th>

                <th width="2%"  v-for="type in testimonialTimeTypes" :key="type.id">
                  {{ 'Minuter ' + filterTimeLabel(type.label) }}
                </th>

                <th
                    width="6%"
                    :class="{ 'sort-up': isSortField('users', true), 'sort-down': isSortField('users', false) }"
                >
                  {{ $t('labels.administrator') }}
                </th>

              </tr>
              </thead>

              <tbody v-for="item in data" :key="item.id">
                <router-link tag="tr" :to="'/cases/time/' + item.id + '/overview'" class="cursor-pointer">
                  <td>{{ item.id }}</td>
                  <td>{{ moment(item.created_at).format("YYYY-MM-DD") }}</td>
                  <td>{{ moment(item.updated_at).format("YYYY-MM-DD") }}</td>
                  <td>{{ $to(item,'court') }}</td>
                  <td v-for="type in testimonialTimeTypes" :key="item.id + type.id">
                    {{ getNumberOfTime(type.label, item.times) }}
                  </td>
                  <td>{{ item.users.name }}</td>
                </router-link>
              </tbody>
            </table>

            <template slot="renderNoResults">
              <div class="empty-placeholder pt-5">
                <i class="far fa-times-circle"></i>
                {{ $t('labels.no-results-found') }}
              </div>
            </template>
          </reactive-list>
        </div>
      </div>
    </div>
    <manual />
  </reactive-base>
</template>
<script>
import CustomTicketResults from '@/components/lists/ticket/CustomTicketResults';
import DatePicker from '@/components/DatePicker';
import BojAPI from '@/api/boj';
import ExportWitnessTime from '@/components/witness/ExportWitnessTime';
import Manual from '@/components/Manual';
import InfoBox from '@/components/InfoBox';
import moment from 'moment';

export default {
  props: {
    title: String,
    defaultQuery: Object,
    stats: {
      default: true,
      type: Boolean,
    },
    enableFilter: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: () => [],
      type: Array,
    },
    testimonialTimeTypes: Array
  },
  components: {
    ExportWitnessTime,
    CustomTicketResults,
    Manual,
    DatePicker,
    InfoBox,
  },

  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      exportEstimate: false,
      ticketStatus: [],
      getSortByOrder: true,
      getSortBy: 'updated_at',
      showFilter: false,
      latestQuery: '',
      moment: moment,
      facets: [
        {
          level: 1,
          visible: true,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centre'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: 'ticket-access-all-tickets',
        },
        {
          level: 1,
          visible: true,
          id: 'localofficeFilter',
          field: 'local_office.raw',
          label: this.$t('labels.local-office'),
          placeholder: this.$t('labels.local-office'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'courtFilter',
          field: 'court.raw',
          label: this.$t('labels.court'),
          placeholder: this.$t('labels.court'),
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'createdDate',
          field: 'created_at',
          label: 'Datum inlagt',
          placeholder: 'Datum inlagt',
          type: 'custom-datepicker',
          singleDatePicker: 'range',
          showDropdowns: true,
          small: true,
          permission: null,
        },
        {
          level: 1,
          visible: true,
          id: 'userSubFilter',
          field: 'users.name.raw',
          nestedField: 'users',
          label: this.$t('labels.administrator'),
          placeholder: this.$t('labels.administrator'),
          type: 'list',
          permission: null,
        }
      ],
    };
  },
  mounted() {
    if (
        Object.keys(this.$route.query).some(function (k) {
          return k.endsWith('SubFilter');
        })
    ) {
      this.showFilter = true;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sort = urlParams.get('sort');
    const sortOrder = urlParams.get('sortOrder');

    if (sort) {
      this.getSortBy = sort;
    }
    if (sortOrder) {
      this.getSortByOrder = sortOrder === 'true';
    }
  },
  computed: {
    visibleTicketStatus: function () {
      return this.$store.getters.getTicketStatuses;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    customTimesAggregation() {
      return {
        aggs: {
          times: {
            nested: {
              path: "times"
            },
            aggs: {
              label: {
                terms: {
                  field: "times.label.raw",
                  order: {
                    _key: "asc"
                  }
                },
                aggs:{
                  time:{
                    sum:{
                      field: "times.time"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    customOrder(data) {
      let customAge = [
        'Barn 0-11',
        'Ungdom 12-17',
        'Ung vuxen 18-25',
        'Vuxen 26-64',
        'Äldre 65 +',
        'Uppgift saknas'
      ];

      let newData = data.slice().sort(function (a, b) {
        return customAge.indexOf(a.key) - customAge.indexOf(b.key);
      });

      return newData;

    },
    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

    /**
     * Toggle Export modal.
     */
    closeExportWitness() {
      this.exportEstimate = null;
    },

    /**
     * Store any changes to the Elasticsearch query
     */
    getQueryChange(prevQuery, nextQuery) {
      this.latestQuery = nextQuery;
    },

    /**
     * Retrieve an estimate before actually exporting tickets.
     */
    exportResultsEstimate(template) {
      BojAPI.exportTestimonyTimeFromElasticEstimate(this.latestQuery).then((response) => {
        this.exportEstimate = response.data;
      });
    },

    /**
     * Begin export of tickets.
     */
    exportResults(template) {
      this.exportEstimate = null;
      BojAPI.exportTestimonyTimeFromElastic(this.latestQuery).then((response) => {
      });
    },

    filterTimeLabel(label) {
      return label.replace('Tid i', '');
    },

    /**
     * Returns facets given by their level value.
     *
     * @param level
     * @returns {({visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, nestedField: string, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string})[]}
     */
    getFacetsByLevel(level) {
      let filters = this.facets.filter((a) => a.level === level);
      let hiddenFields = this.hide;
      filters = filters.filter(function (el) {
        return !hiddenFields.includes(el.field);
      });
      return filters;
    },

    /**
     * Add filter ids not present in facets.
     *
     * @param id
     * @returns {*[]}
     */
    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id !== id).map((a) => a.id);
      ids.push('DataControllerSensor');
      ids.push('createdDate');
      ids.push('updateAtDate');

      return ids;
    },

    /**
     * Page Change Hook.
     *
     * Added to avoid scrolling of page when navigating through
     * the pages using the reactivesearch pagination.
     *
     */
    pageChange() {
    },

    getNumberOfTime(key, obj) {
      let n = obj.find((e) => e.label === key);

      if (n) {
        return n.time;
      } else {
        return '0';
      }
    },

    /**
     * Return values from elasticsearch aggregations.
     *
     * @param searchState
     * @param componentId
     * @param aggregationId
     * @param key
     * @returns {string|number}
     */
    getBucketArray(searchState, componentId, aggregationId, key) {
       if (searchState[componentId] === undefined) {
        return
      }

      if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId];

        if (Object.prototype.hasOwnProperty.call(aggregation, key)) {
          let buckets = searchState[componentId].aggregations[aggregationId][key].buckets;
          if (buckets) {
            return buckets
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }

      return 0;
    },

    /**
     * Return values from elasticsearch aggregations.
     *
     * @param searchState
     * @param componentId
     * @param aggregationId
     * @param key
     * @returns {string|number}
     */
    getBucketCount(searchState, componentId, aggregationId, key, aggregationIdLabel) {
      if (searchState[componentId] === undefined) {
        return
      }

      if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId][aggregationIdLabel];

        if (aggregationIdLabel && Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId][aggregationIdLabel].buckets.find((item) => item.key === key);

          if (buckets) {
            return buckets;
          } else {
            return 0;
          }

        } else if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);

          if (buckets) {
            return buckets.doc_count.toLocaleString('sv-SE');
          } else {
            return 0;
          }
        } else if (Object.prototype.hasOwnProperty.call(aggregation, 'doc_count')) {
          let buckets = searchState[componentId].aggregations[aggregationId].doc_count;

          if (buckets) {
            return buckets;
          } else {
            return 0;
          }

        } else {
          return 0;
        }
      } else if (Object.keys(searchState).length && Object.prototype.hasOwnProperty.call(searchState[componentId], 'hits')) {
        let hits = searchState[componentId].hits;

        if (Object.prototype.hasOwnProperty.call(hits, 'total')) {
          return searchState[componentId].hits.total.toLocaleString('sv-SE')
        } else {
          return 0;
        }
      }
      return 0;
    },

    minutes_to_hhmm (numberOfMinutes) {
      const duration = moment.duration(numberOfMinutes, 'minutes');

      const hh = (duration.years()*(365*24)) + (duration.months()*(30*24)) + (duration.days()*24) + (duration.hours());

      const mm = duration.minutes();

      return hh.toLocaleString('sv-SE') + ' tim, '+mm+' min';
    },

    /**
     * Determine if a field is currently used for sorting.
     *
     * @param field
     * @param direction
     * @returns {boolean}
     */
    isSortField(field, direction) {
      return this.getSortBy === field && this.getSortByOrder === direction;
    },

    /**
     * Toogle extra fields
     */
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    /**
     * Change sort attribute for the results.
     *
     * @param value
     */
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;

      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    /**
     * Updates the query string paramter.
     *
     * Currently used for adding / replacing the current sort.
     *
     * @param key
     * @param value
     */
    updateQueryStringParam(key, value) {
      const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
      const urlQueryString = document.location.search;
      const newParam = key + '=' + value;
      let params = '?' + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        const keyRegex = new RegExp('([?&])' + key + '[^&]*');

        // If param exists already, update it - otherwise, add it to end of query string
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },
  },
};
</script>
