<template>
  <layout-main>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.administrator') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/cases" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/cases" class="breadcrumbs-link"> {{ $t('labels.administrator') }}</router-link>
            </div>
          </div>

        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <witness-list
            :enable-filter="$store.getters.permissions.includes('court-page-active-use-filter')"
            ref="witnesslist"
            :default-query="query"
            title="Handläggare"
            :infoBox="'Domstol - handläggare'"
        ></witness-list>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Notifications from '@/components/dashboard/Notifications.vue';
import WitnessList from '@/components/lists/witness/WitnessUserList.vue';
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'home',
  components: {
    LayoutMain,
    Notifications,
    WitnessList,
    InfoBox
  },
  data() {
    return {
      isDownloading: false,
      query: {},
    };
  },
};
</script>
