<template>
  <layout-main>
    <div class="survey">
      <div class="subheader">
        <div class="subheader-main">
          <h3>Registrera tid</h3>
          <span class="separator-line"></span>
          <div class="breadcrumbs">
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/cases" class="breadcrumbs-link"> Domstol</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xl-12">
          <div v-if="currentStep === 3">
            <div class="text-center text-muted pt-5">
              <h5>
                <i class="fas fa-circle-notch fa-spin"></i>
                <div class="mt-4">Registrerar tiden...</div>
              </h5>
            </div>
          </div>

          <div v-if="currentStep === 1">
            <div class="row">

              <div class="col-3">
                <div class="widget">
                  <div class="widget-header">
                    <div class="widget-header-label">
                      <h3>
                        Registrera tid
                      </h3>
                    </div>
                  </div>
                  <div class="widget-body">
                    <div class="form-group">
                      <label>Domstol: <span class="text-danger">*</span></label>

                      <select-field
                        v-if="testimonyFieldOptions.courts"
                        :clearable="false"
                        :model="newTestimonyTime"
                        :multiple="false"
                        :options="testimonyFieldOptions.courts"
                        db_field=""
                        field="court"
                        label=""
                        :optionLabel="$label()"
                      ></select-field>

                    </div>
                    <div v-for="type, index in testimonyFieldOptions.testimony_time_types" :key="type.id" class="form-group">
                      <label>{{ type.label }}: <info-box :field="'Domstol - Nedlagd tid - '+ type.label +''" /></label>
                      <input
                        type="number"
                        :value="newTestimonyTime.timeTypes[index].time"
                        class="form-control"
                        :data-index="index"
                        placeholder="Ange tid i minuter"
                        pattern="\d*"
                        @input="limitCharacters"
                      />

                    </div>

                  </div>
                  <div class="widget-footer pt-3 pb-3 text-right">

                    <!-- <v-guard :permissions="['ticket-access-office', 'ticket-access-all-tickets']"> -->
                      <button @click="createTime" class="btn btn-success"
                              :disabled="Object.keys(newTestimonyTime.court).length === 0">
                        Rapportera tid
                      </button>
                    <!-- </v-guard> -->

                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import BojAPI from '@/api/boj';
import 'vue-select/dist/vue-select.css';
import NewTicketOfficeFilter from '@/components/ticket/NewTicketOfficeFilter';
import SelectField from '@/components/ticket/fields/SelectField';
import InfoBox from '@/components/InfoBox';
import moment from 'moment';

export default {
  name: 'ticket-page',
  components: {
    LayoutMain,
    NewTicketOfficeFilter,
    SelectField,
    InfoBox
  },
  props: {
    id: Number,
  },
  data() {
    return {
      newTestimonyTime: {
        court: {},
        timeTypes: []
      },
      maxLength: 9,
      responseTestimony: {},
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      currentStep: 1,
      moment: moment,
      showLeaveConfirmation: false,
      testimonyFieldOptions: {},
      isLoadingOptions: false,
    };
  },
  mounted() {
    this.getTestimonyOptions();
  },
  methods: {
    limitCharacters(event) {
      const value = event.target.value
      const index = event.target.dataset.index

      if (String(value).length <= this.maxLength) {
        if (!value) {
          this.newTestimonyTime.timeTypes[index].time = ''
        } else {
          this.newTestimonyTime.timeTypes[index].time = parseInt(value)
        }
      }
      this.$forceUpdate()
    },
    getTestimonyOptions() {
      this.isLoadingOptions = true

      BojAPI.getTestimonyOptions().then((response) => {
        this.testimonyFieldOptions = response.data;
        this.newTestimonyTime.timeTypes = this.testimonyFieldOptions.testimony_time_types;
        this.isLoadingOptions = false
      });
    },

    createTime() {
      this.currentStep = 3;

      BojAPI.createTestimonyTime(this.newTestimonyTime)
        .then((response) => {
          setTimeout( () => {
            this.$router.push({
              name: 'case.time',
              params: {id: response.data.id},
            });
          }, 2000);

        })
        .catch((error) => {
          /**
           *
           * TODO: Check if user has a member
           *
           **/

          this.$notify({
            group: 'foo',
            text: 'Något gick snett',
            type: 'warn',
            duration: 3000,
          });
        })
    },
    beforeRouteLeave(to, from, next) {
      if (this.showLeaveConfirmation) {
        const answer = window.confirm(this.$t('labels.close-ticket-without-saving'));
        if (answer) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    }
  }
};
</script>
