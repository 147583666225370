<template>
  <reactive-base app="witness_index,witness_time" :credentials="user.user.es_username + ':' + user.user.es_password"
    :url="elasticUrl">
    <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
      <div class="widget">
        <div class="widget-header">
          <div class="widget-header-label">
            <h3>{{ title }} <info-box :field="infoBox" /><small></small></h3>

          </div>
          <div class="widget-header-toolbar">
            <div class="actions" v-show="enableFilter">
              <div class="filter-action filter-action-lg">
                <multi-dropdown-list :placeholder="this.$t('labels.victim-support-centre')" componentId="officeFilter"
                  :URLParams="true" sortBy="asc" :size="100" fuzziness="AUTO" :showSearch="true"
                  :filterLabel="this.$t('labels.victim-support-centres')" dataField="office.raw" :react="{
                    and: [
                      'SearchResult',
                      'userSubFilter',
                      'prioritySubFilter',
                      'ageSubFilter',
                      'riskFilter',
                      'categorySubFilter',
                      'crimeSubFilter',
                      'statusSubFilter',
                      'courtFilter',
                      'createdDateFilters'
                    ],
                  }" />
              </div>

              <div class="filter-action filter-action-lg ml-3">
                <multi-dropdown-list :placeholder="this.$t('labels.court')" componentId="courtFilter"
                  :filterLabel="this.$t('labels.court')" dataField="court.raw" sortBy="asc" :URLParams="true"
                  :showSearch="true" :react="{
                    and: [
                      'officeFilter',
                      'userSubFilter',
                      'prioritySubFilter',
                      'ageSubFilter',
                      'riskFilter',
                      'categorySubFilter',
                      'crimeSubFilter',
                      'statusSubFilter',
                      'createdDateFilters'
                    ],
                  }" />
              </div>

              <div class="filter-action-sm ml-3">
                <ReactiveComponent componentId="createdDateFilters" :URLParams="true" filterLabel="created_at" :react="{
                  and: [
                    'officeFilter',
                    'userSubFilter',
                    'prioritySubFilter',
                    'ageSubFilter',
                    'riskFilter',
                    'categorySubFilter',
                    'crimeSubFilter',
                    'statusSubFilter',
                    'courtFilter'
                  ],
                }">
                  <div slot-scope="{ aggregations, setQuery, value }">
                    <date-picker opens="left" placeholder="Inlagt" label="" dataField="created_at"
                      :aggregations="aggregations" :setQuery="setQuery" :selectedValue="value" :show-dropdowns="true"
                      single-date-picker="range"></date-picker>
                  </div>
                </ReactiveComponent>
              </div>

              <div class="filter-action ml-3 filter-action">
                <multi-dropdown-list :placeholder="this.$t('labels.administrator')" componentId="userSubFilter"
                  sortBy="asc" :size="100" dataField="users.name.raw" nestedField="users"
                  :filterLabel="this.$t('labels.administrator')" :URLParams="true" :react="{
                    and: [
                      'officeFilter',
                      'genderSubFilter',
                      'ageSubFilter',
                      'prioritySubFilter',
                      'riskFilter',
                      'categorySubFilter',
                      'crimeSubFilter',
                      'courtFilter',
                      'createdDateFilters',
                    ],
                  }" :defaultQuery="() => ({
    aggs: {
      reactivesearch_nested: {
        nested: {
          path: 'users'
        },
        aggs: {
          'users.name.raw': {
            terms: {
              field: 'users.name.raw',
              size: 1000,
              order: {
                _key: 'asc'
              }
            }
          }
        }
      }
    }
  })
  " />
              </div>

            </div>
          </div>
        </div>

        <div class="widget-body no-padding">
          <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')" />
        </div>
        <div class="widget-body no-padding">
          <reactive-component componentId="myColorPicker" :react="{
            and: [
              'riskPlaceholder',
              'officeFilter',
              'prioritySubFilter',
              'genderSubFilter',
              'ageSubFilter',
              'prioritySubFilter',
              'riskFilter',
              'categorySubFilter',
              'userSubFilter',
              'crimeSubFilter',
              'statusSubFilter',
              'courtFilter',
              'createdDateFilters'
            ],
          }" :defaultQuery="() => ({
    aggs: {
      users: {
        nested: {
          path: 'users'
        },
        aggs: {
          name: {
            terms: {
              field: 'users.name.raw',
              size: 1000,
              order: {
                _key: 'asc'
              }
            },
            aggs: {
              supporter_status_tag: {
                terms: {
                  field: 'users.supporter_status_tag.raw',
                  size: 1,
                  min_doc_count: 0,
                },
              },
              all_times: {
                reverse_nested: {},
                aggs: {
                  times: {
                    nested: {
                      path: 'times'
                    },
                    aggs: {
                      type: {
                        terms: {
                          field: 'times.label.raw'
                        },
                        aggs: {
                          time: {
                            sum: {
                              field: 'times.time'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      time_users: {
        nested: {
          path: 'time_users'
        },
        aggs: {
          name: {
            terms: {
              field: 'time_users.name.raw',
              size: 1000,
              order: {
                _key: 'asc'
              }
            },
            aggs: {
              all_times: {
                reverse_nested: {},
                aggs: {
                  times: {
                    nested: {
                      path: 'times'
                    },
                    aggs: {
                      type: {
                        terms: {
                          field: 'times.label.raw'
                        },
                        aggs: {
                          time: {
                            sum: {
                              field: 'times.time'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      persons_count: {
        nested: {
          path: 'persons'
        },
        aggs: {
          name: {
            terms: {
              field: 'persons.user.keyword',
              size: 1000,
              order: {
                _key: 'desc'
              }
            }
          }
        }
      },
      testimonies: {
        filter: {
          term: {
            _index: 'witness_index'
          }
        },
        aggs: {
          users: {

            terms: {
              field: 'testimony_users.name.keyword',
              size: 1000,
              order: {
                _key: 'asc'
              }
            }
          }
        }
      }


    }
  })
  ">
            <div slot-scope="{ aggregations, hits, setQuery, loading }">

              <div class="row">
                <div v-if="!loading" class="col-12">

                  <table class="vuetable" v-for="item in getAdministrators(aggregations)" :key="item.key"
                    style="table-layout: fixed;">
                    <thead>
                      <tr>
                        <th width="3%">
                          <status-icon
                            v-if="item.supporter_status_tag.buckets[0] && item.supporter_status_tag.buckets[0].doc_count > 0"
                            style="position: absolute; left:20px;"
                            :supporterStatusTag="item.supporter_status_tag.buckets[0].key"></status-icon> {{ item.key }}
                        </th>

                        <th width="2%">
                          Antal mål
                        </th>

                        <th width="2%">
                          Stödsökande
                        </th>

                        <th width="2%">
                          Antal pass
                        </th>

                        <th width="2%" v-for="type in testimonialTimeTypes" :key="type.id">
                          {{ type.label }}
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td style="padding-left: 20px;"></td>
                        <td>{{ "testimony_count" in item ? item.testimony_count : 0 }}</td>
                        <td>{{ item.persons_count ? item.persons_count.doc_count : 0 }}</td>
                        <td>{{ item.time_count }}</td>
                        <td v-for="type in testimonialTimeTypes" :key="item.key + type.id">
                          {{ getNumberOfTime(type.label, item) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <!-- <div v-if="loading" class="col-12">
                  <h3>LADDAR</h3>
                </div> -->

              </div>
            </div>
          </reactive-component>
        </div>
      </div>
    </div>
  </reactive-base>
</template>
<script>
import CustomTicketResults from '@/components/lists/ticket/CustomTicketResults';
import BojAPI from '@/api/boj';
import StatisticWidget from '@/components/widgets/StatisticWidget.vue';
import InfoBox from '@/components/InfoBox.vue';
import DatePicker from '@/components/DatePicker';
import moment from 'moment';
import StatusIcon from '@/components/member/StatusIcon';
import _ from 'lodash';

export default {
  props: {
    title: String,
    defaultQuery: Object,
    enableFilter: {
      default: false,
      type: Boolean,
    },
    infoBox: String,
  },
  components: {
    CustomTicketResults,
    StatisticWidget,
    InfoBox,
    DatePicker,
    StatusIcon,
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      testimonialTimeTypes: [],
      getSortByOrder: true,
      getSortBy: '_score',
      showFilter: false,
    };
  },
  mounted() {
    if (
      Object.keys(this.$route.query).some(function (k) {
        return k.endsWith('SubFilter');
      })
    ) {
      this.showFilter = true;
    }

    this.getTestimonialTimeTypes();
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    visibletestimonialTimeTypes: function () {
      return this.testimonialTimeTypes.filter((i) => i.show_on_summary === 1);
    },
  },
  methods: {
    query: () => {
      return {
        query: {},
      };
    },

    pageChange() { },

    minutes_to_hhmm(numberOfMinutes) {
      const duration = moment.duration(numberOfMinutes, 'minutes');

      const hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());

      const mm = duration.minutes();

      return hh + ' tim, ' + mm + ' min';

    },

    getNumberOfTime(key, obj) {

      // if (!("all_times" in obj)) {
      //   return '0';
      // }



      let n = obj.time.times.type.buckets.find((e) => e.key === key);

      if (n) {
        return this.minutes_to_hhmm(n.time.value);
      } else {
        return '0';
      }
    },

    getBucketCount(searchState, componentId, aggregationId, key) {
      if (Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId];
        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);
          if (buckets) {
            return buckets.doc_count.toLocaleString('sv-SE');
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },

    getTestimonialsBucketCount(agg, key) {
      return agg.find((item) => item.key === key);
    },

    getAdministrators(aggs) {
      if (aggs === null) return null;



      const combinedBuckets = aggs.testimonies.users.buckets.map((item) => {
        // let user = aggs.user.name.buckets.find((e) => e.key === item.key);
        let person = aggs.persons_count.name.buckets.find((e) => e.key === item.key);
        // let time = aggs.users.name.buckets.find((e) => e.key === item.key)

        return {
          key: item.key,
          doc_count: item.doc_count,
          persons_count: person,
          supporter_status_tag: item.supporter_status_tag,
          // ...user,
          // time: time.all_times,
          // time_count: time.all_times.times.doc_count ? time.all_times.doc_count : 0,
          testimony_count: item.doc_count ?? 0,
        }
      })

      const combinedBuckets2 = aggs.users.name.buckets.map((item) => {
        // let user = aggs.user.name.buckets.find((e) => e.key === item.key);
        let person = aggs.persons_count.name.buckets.find((e) => e.key === item.key);

        let testi = aggs.testimonies.users.buckets.find((e) => e.key === item.key);

        return {
          key: item.key,
          doc_count: item.doc_count,
          persons_count: person,
          supporter_status_tag: item.supporter_status_tag,
          time: item.all_times,
          time_count: item.all_times.times.doc_count ? item.all_times.doc_count : 0,
          // ...user,
          testimony_count: testi !== undefined ? testi.doc_count : 0,
        }
      })

      let mergedBuckets = [...combinedBuckets2, ...combinedBuckets];

      let set = new Set();
      let unionArray = mergedBuckets.filter(item => {
        if (!set.has(item.key)) {
          set.add(item.key);
          return true;
        }
        return false;
      }, set).sort(function (a, b) {
        if (a.key < b.key) { return -1; }
        if (a.key > b.key) { return 1; }

        return 0;
      }); 

      return unionArray;
    },

    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
    },

    getTestimonialTimeTypes() {
      BojAPI.getTestimonialTimeTypes().then((response) => {
        this.testimonialTimeTypes = response.data;
      });
    },
  },
};
</script>