<template>
  <layout-main>
    <div class="survey">
      <div class="subheader">
        <div class="subheader-main">
          <h3>{{ $t('labels.new-ticket') }}</h3>
          <span class="separator-line"></span>
          <div class="breadcrumbs">
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.ticket') }}</router-link>
            <span class="separator-dot"></span>
            <router-link to="/" class="breadcrumbs-link"> {{ $t('labels.overview') }}</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xl-12">
          <div v-if="currentStep === 3">
            <div class="text-center text-muted pt-5">
              <h5>
                <i class="fas fa-circle-notch fa-spin"></i>
                <div class="mt-4">{{ $t('labels.creating-ticket') }}..</div>
              </h5>
            </div>
          </div>

          <div v-if="currentStep === 1">
            <div class="row">
              <div class="col-9">
                <div class="widget comments">
                  <reactive-base
                      app="ticket_index"
                      :credentials="user.user.es_username + ':' + user.user.es_password"
                      :url="elasticUrl"
                  >
                    <div class="widget-header">
                      <div class="widget-header-label">
                        <h3>{{ $t('labels.search-existing-tickets') }}</h3>
                      </div>
                      <div class="widget-header-toolbar">
                        <div class="actions">
                          <div v-for="facet in getFacetsByLevel(1)" :key="facet.id">
                              <div
                                :class="{
                                'filter-action-sm': facet.small,
                                'filter-action': facet.type === 'list',
                                'search-action': facet.type === 'search',
                              }"
                                class="search-action ml-3" style="width: 140px;"
                                v-if="checkFacetPermission(facet.permission)"
                            >
                              <multi-dropdown-list
                                  v-if="facet.type === 'list'"
                                  :placeholder="facet.placeholder"
                                  :componentId="facet.id"
                                  :URLParams="true"
                                  sortBy="asc"
                                  :size="300"
                                  :nestedField="facet.nestedField"
                                  fuzziness="AUTO"
                                  searchPlaceholder="Sök..."
                                  :showSearch="true"
                                  :filterLabel="facet.label"
                                  :dataField="facet.field"
                                  :react="{ and: getOtherFilterIds(facet.id) }"
                              />

                                <data-search
                                  v-if="facet.type === 'search'"
                                  :componentId="facet.id"
                                  :dataField="facet.field"
                                  iconPosition="right"
                                  className="data-search"
                                  :showClear="true"
                                  :URLParams="true"
                                  fuzziness="AUTO"
                                  :placeholder="facet.placeholder"
                                  :debounce="100"
                                  :autosuggest="false"
                                  :filterLabel="facet.label"
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="widget-body no-padding bb-1">
                      <reactive-list
                          @pageChange="pageChange"
                          componentId="SearchResult"
                          paginationAt="bottom"
                          :dataField="getSortBy"
                          :sortBy="getSortByOrder ? 'desc' : 'asc'"
                          :stream="false"
                          :pagination="true"
                          :pages="5"
                          :size="10"
                          :showResultStats="false"
                          :react="{ and: getOtherFilterIds() }"

                      >
                        <template slot="renderNoResults">
                          <div class="empty-placeholder pt-5">
                            <i class="far fa-times-circle"></i>
                            {{ $t('labels.no-results-found') }}
                          </div>
                        </template>
                        <table class="w-100 clickable-vuetable vuetable" slot="render"
                               slot-scope="{ loading, error, data }">
                          <thead v-if="data.length !== 0">
                          <tr>
                            <th
                                width="6%"
                                :class="{
																	'sort-up': isSortField('id', true),
																	'sort-down': isSortField('id', false),
																}"
                                @click="changeSortBy('id')"
                            >
                              #
                            </th>
                            <th
                                width="9%"
                                :class="{
																	'sort-up': isSortField('created_at', true),
																	'sort-down': isSortField('created_at', false),
																}"
                                @click="changeSortBy('created_at')"
                            >
                              {{ $t('labels.created') }}
                            </th>
                            <th
                                width="11%"
                                :class="{
																	'sort-up': isSortField('office.raw', true),
																	'sort-down': isSortField('office.raw', false),
																}"
                                @click="changeSortBy('office.raw')"
                            >
                              {{ $t('labels.duty') }}
                            </th>
                            <th
                                width="11%"
                                :class="{
																	'sort-up': isSortField('localoffice.raw', true),
																	'sort-down': isSortField('localoffice.raw', false),
																}"
                                @click="changeSortBy('localoffice.raw')"
                            >
                              {{ $t('labels.local-office') }}
                            </th>
                            <th
                                width="16%"
                                :class="{
																	'sort-up': isSortField('person_last_name.raw', true),
																	'sort-down': isSortField('person_last_name.raw', false),
																}"
                                @click="changeSortBy('person_last_name.raw')"
                            >
                              {{ $t('labels.name') }}
                            </th>
                            <th
                                width="16%"
                                :class="{
																	'sort-up': isSortField('crime.raw', true),
																	'sort-down': isSortField('crime.raw', false),
																}"
                                @click="changeSortBy('crime.raw')"
                            >
                              {{ $t('labels.crime') }}
                            </th>
                            <th
                                width="5%"
                                :class="{
																	'sort-up': isSortField('risk.raw', true),
																	'sort-down': isSortField('risk.raw', false),
																}"
                                @click="changeSortBy('risk.raw')"
                            >
                              {{ $t('labels.risk') }}
                            </th>
                          </tr>
                          </thead>
                          <tbody v-for="item in data" :key="item.id">
                          <router-link tag="tr" :to="'/tickets/ticket/' + item.id + '/overview'" class="cursor-pointer">
                            <td>{{ item.id }}</td>
                            <td>{{ moment(item.created_at).format("YYYY-MM-DD") }}</td>
                            <td>{{ item.office }}</td>
                            <td>{{ item.localoffice }}</td>
                            <td>{{ item.person_first_name }} {{ item.person_last_name }}</td>
                            <td>{{ item.crime }}</td>
                            <td
                                class="text-center pl-0"
                                v-html="item.risk ? '<i class=\'fas fa-exclamation-triangle text-danger\'></i>' : ''"
                            ></td>
                          </router-link>
                          </tbody>
                        </table>
                      </reactive-list>
                    </div>
                  </reactive-base>
                </div>
              </div>
              <div class="col-3">
                <div class="widget">
                  <div class="widget-header">
                    <div class="widget-header-label">
                      <h3>
                        {{ $t('labels.create-new-ticket') }}
                      </h3>
                    </div>
                  </div>
                  <div class="widget-body">
                    <div class="form-group">
                      <label>{{ $t('labels.first-name') }}: <span class="text-danger">*</span></label>
                      <input type="text" v-model="newTicket.person.first_name" class="form-control" placeholder=""/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.last-name') }}:</label>
                      <input type="text" v-model="newTicket.person.last_name" class="form-control" placeholder=""/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.email') }}:</label>
                      <input type="email" v-model="newTicket.person.email" class="form-control" placeholder=""/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.phone') }}:</label>
                      <input type="text" v-model="newTicket.person.phone" class="form-control" placeholder=""/>
                    </div>
                    <div class="form-group">
                      <label>{{ $t('labels.mobile') }}:</label>
                      <input type="text" v-model="newTicket.person.mobile" class="form-control" placeholder=""/>
                    </div>
                  </div>
                  <div class="widget-footer pt-3 pb-3 text-right">
                    <v-guard :permissions="['ticket-access-only-local-office', 'ticket-access-assigned-tickets']">
                      <button
                          @click="setLocalOfficeAndCreateTicket(user.user.member.local_office_id)"
                          class="btn btn-success"
                          :disabled="newTicket.person.first_name.length === 0"
                          v-if="!$store.getters.permissions.includes('ticket-access-office')"
                      >
                        {{ $t('labels.create-ticket') }}
                      </button>
                    </v-guard>

                    <v-guard :permissions="['ticket-access-office', 'ticket-access-all-tickets']">
                      <button @click="nextStep" class="btn btn-success"
                              :disabled="newTicket.person.first_name.length === 0">
                        {{ $t('labels.create-ticket') }}
                      </button>
                    </v-guard>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="currentStep === 2">
            <div class="row">
              <reactive-base
                  app="ticket_local_office"
                  :credentials="user.user.es_username + ':' + user.user.es_password"
                  :url="elasticUrl"
              >
                <div class="widget widget-modal">
                  <div class="widget-header">
                    <div class="widget-header-toolbar">
                      <div class="actions">
                        <div class="filter-action ml-3">
                          <multi-dropdown-list
                              :placeholder="this.$t('labels.police-region')"
                              componentId="policeFilter"
                              :URLParams="true"
                              fuzziness="AUTO"
                              :showSearch="true"
                              sortBy="asc"
                              nestedField="police_region"
                              :react="{
															and: [
																'DataControllerSensor',
																'search',
																'postalCode',
																'countyFilter',
																'municipalityFilter',
																'officeFilter',
															],
														}"
                              :filterLabel="this.$t('labels.police-region')"
                              dataField="police_region.label.raw"
                          ></multi-dropdown-list>
                        </div>
                        <div class="filter-action ml-3">
                          <multi-dropdown-list
                              :placeholder="this.$t('labels.county')"
                              componentId="countyFilter"
                              :URLParams="true"
                              fuzziness="AUTO"
                              :showSearch="true"
                              :size="100"
                              sortBy="asc"
                              nestedField="county"
                              :react="{
															and: [
																'DataControllerSensor',
																'search',
																'postalCode',
																'policeFilter',
																'municipalityFilter',
																'officeFilter',
															],
														}"
                              :filterLabel="this.$t('labels.county')"
                              dataField="county.label.raw"
                          ></multi-dropdown-list>
                        </div>
                        <div class="filter-action ml-3">
                          <multi-dropdown-list
                              :placeholder="this.$t('labels.municipality')"
                              componentId="municipalityFilter"
                              :URLParams="true"
                              fuzziness="AUTO"
                              sortBy="asc"
                              :showSearch="true"
                              :size="1000"
                              nestedField="municipality"
                              :react="{
															and: [
																'DataControllerSensor',
																'search',
																'postalCode',
																'policeFilter',
																'countyFilter',
																'officeFilter',
															],
														}"
                              :filterLabel="this.$t('labels.municipality')"
                              dataField="municipality.label.raw"
                          ></multi-dropdown-list>
                        </div>
                        <div class="filter-action ml-3">
                          <multi-dropdown-list
                              :placeholder="this.$t('labels.victim-support-centre')"
                              componentId="officeFilter"
                              :URLParams="true"
                              sortBy="asc"
                              fuzziness="AUTO"
                              :size="100"
                              :showSearch="true"
                              :react="{
															and: [
																'DataControllerSensor',
																'search',
																'postalCode',
																'policeFilter',
																'countyFilter',
																'municipalityFilter',
															],
														}"
                              :filterLabel="this.$t('labels.victim-support-centre')"
                              dataField="office.raw"
                          ></multi-dropdown-list>
                        </div>
                        <div class="search-action ml-3">
                          <data-search
                              componentId="postalCode"
                              dataField="postal_codes.postal_code.raw"
                              nestedField="postal_codes"
                              iconPosition="right"
                              className="data-search"
                              :showClear="true"
                              :URLParams="true"
                              :fuzziness="0"
                              :debounce="100"
                              :placeholder="`${this.$t('labels.postal-code')}..`"
                              :filterLabel="this.$t('labels.postal-code')"
                              :autosuggest="false"
                          />
                        </div>
                        <div class="search-action ml-3">
                          <data-search
                              componentId="search"
                              :dataField="[
															'label',
															'label_raw',
															'other_places_indexed',
															'post_towns_indexed',
															'exposed_areas_indexed',
															'office',
															'municipality_indexed',
															'police_region_indexed',
															'county_list',
														]"
                              iconPosition="right"
                              className="data-search"
                              :showClear="true"
                              :highlight="true"
                              :URLParams="true"
                              :fuzziness="2"
                              :placeholder="`${this.$t('labels.search')}..`"
                              :debounce="100"
                              :filterLabel="this.$t('labels.search')"
                              :autosuggest="false"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="widget-body no-padding">
                    <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
                  </div>
                  <ReactiveComponent componentId="DataControllerSensor">
                    <div slot-scope="{ setQuery }">
                      <new-ticket-office-filter :setQuery="setQuery" :query="defaultQuery"/>
                    </div>
                  </ReactiveComponent>
                  <div class="widget-body no-padding">
                    <reactive-list
                        :showResultStats="false"
                        componentId="SearchResult"
                        className="result-list-container"
                        :pagination="true"
                        :dataField="getSortBy"
                        :sortBy="getSortByOrder ? 'desc' : 'asc'"
                        :URLParams="true"
                        :from="0"
                        :size="15"

                        :react="{
												and: [
													'DataControllerSensor',
													'search',
													'postalCode',
													'policeFilter',
													'countyFilter',
													'municipalityFilter',
													'officeFilter',
												],
											}"
                    >
                      <template slot="renderNoResults">
                        <div class="empty-placeholder pt-5">
                          <i class="far fa-times-circle"></i>
                          {{ $t('labels.no-results-found') }}
                        </div>
                      </template>
                      <table class="w-100 clickable-vuetable vuetable" slot="render"
                             slot-scope="{ loading, error, data }">
                        <thead v-if="data.length !== 0">
                        <tr>
                          <th
                              width="15%"
                              :class="{
																'sort-up': isSortField('office.raw', true),
																'sort-down': isSortField('office.raw', false),
															}"
                              @click="changeSortBy('office.raw')"
                          >
                            {{ $t('labels.victim-support-centre') }}
                          </th>
                          <th
                              width="12%"
                              :class="{ 'sort-up': isSortField('label.raw', true), 'sort-down': isSortField('label.raw', false) }"
                              @click="changeSortBy('label.raw')"
                          >
                            {{ $t('labels.local-office') }}
                          </th>
                          <th
                              width="23%"
                              :class="{
																'sort-up': isSortField('municipality_list.raw', true),
																'sort-down': isSortField('municipality_list.raw', false),
															}"
                              @click="changeSortBy('municipality_list.raw')"
                          >
                            {{ $t('labels.municipality') }}
                          </th>
                          <th
                              width="40%"
                              :class="{
																'sort-up': isSortField('police_region_list.raw', true),
																'sort-down': isSortField('police_region_list.raw', false),
															}"
                              @click="changeSortBy('police_region_list.raw')"
                          >
                            {{ $t('labels.place') }}
                          </th>
                          <th width="15%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in data" :key="item.id">
                          <td width="15%" v-html="item.office"></td>
                          <td width="12%" v-html="item.label"></td>
                          <td width="23%" v-html="item.municipality_indexed.replace(/,/g, ', ')"></td>
                          <td width="40%" style="font-size: 11px;"
                              v-html="item.post_towns_indexed.replace(/,/g, ', ')"></td>
                          <td width="18%" class="text-right pr-4">
                            <router-link
                                :to="'/offices/localoffice/' + item.id + '/overview'"
                                target="_blank"
                                class="btn btn-outline-dark opacity-40"
                            ><i class="fas fa-search"></i>
                            </router-link>
                            <button class="ml-3 btn btn-success" @click="setLocalOffice(item.id)">
                              <i class="fas fa-arrow-right"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </reactive-list>
                  </div>
                </div>
              </reactive-base>
            </div>
            <div class="widget-body text-right">
              <button @click="previousStep" class="btn btn-outline-grey mr-4">
                {{ $t('labels.previous') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import BojAPI from '@/api/boj';
import 'vue-select/dist/vue-select.css';
import NewTicketOfficeFilter from '@/components/ticket/NewTicketOfficeFilter';
import moment from 'moment';

export default {
  name: 'ticket-page',
  components: {
    LayoutMain,
    NewTicketOfficeFilter,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      isSavingTicket: true,
      getSortByOrder: true,
      getSortBy: '_score',
      currentStep: 1,
      moment: moment,
      steps: [
        {
          id: 1,
          label: 'Person',
          completed: false,
        },
        {
          id: 2,
          label: 'Förmedling',
          completed: false,
        },
      ],

      showLeaveConfirmation: false,

      newTicket: {
        person: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          mobile: '',
        },
        priority_id: {id: 2},
        local_office_id: {},
      },
      responseTicket: {},
      ticket: {
        call_duration: 0,
        information: '',
        gender: '',
      },
      selected_person: false,
      hide: [],
      facets: [
        {
          level: 1,
          visible: true,
          id: 'officeFilter',
          field: 'office.raw',
          label: this.$t('labels.victim-support-centres'),
          placeholder: this.$t('labels.victim-support-centre'),
          type: 'list',
          permission: 'ticket-access-all-tickets',
        },
        {
          level: 1,
          visible: true,
          id: 'municipalityFilter',
          field: 'municipality.raw',
          label: 'Kommun',
          placeholder: 'Kommun',
          type: 'list',
          permission: null,
        },
        {
          level: 1,
          id: 'search',
          visible: true,
          field: 'person_name',
          label: 'Sök',
          placeholder: `${this.$t('labels.name')}..`,
          type: 'search',
          permission: null,
        },
        {
          level: 1,
          id: 'phoneFilter',
          visible: true,
          field: ['person_phone', 'person_phone_work', 'person_mobile'],
          label: 'Telefon #',
          placeholder: 'Telefon #..',
          type: 'search',
          permission: 'ticket-search-view-phone',
        },
        {
          level: 1,
          id: 'searchID',
          visible: true,
          field: 'id_searchable',
          label: 'Ärende #',
          placeholder: 'Ärende #..',
          type: 'search',
          permission: null,
        },
      ]
    };
  },
  methods: {
    /**
     * Returns facets given by their level value.
     *
     * @param level
     * @returns {({visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, nestedField: string, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string}|{visible: boolean, field: string, level: number, id: string, label: string, placeholder: string, type: string})[]}
     */
    getFacetsByLevel(level) {
      let filters = this.facets.filter((a) => a.level === level);
      let hiddenFields = this.hide;
      filters = filters.filter(function (el) {
        return !hiddenFields.includes(el.field);
      });
      return filters;
    },

    checkFacetPermission(permission) {
      if (permission === null) {
        return true;
      }

      return this.$store.getters.permissions.includes(permission);
    },

     /**
     * Add filter ids not present in facets.
     *
     * @param id
     * @returns {*[]}
     */
    getOtherFilterIds(id) {
      let ids = this.facets.filter((a) => a.id !== id).map((a) => a.id);
      ids.push('DataControllerSensor');
      ids.push('riskFilter');
      ids.push('search');

      return ids;
    },
    setLocalOfficeAndCreateTicket(id) {
      this.newTicket.local_office_id = {id: id};
      this.nextStep();
      this.nextStep();
    },

    setLocalOffice(id) {
      this.newTicket.local_office_id = {id: id};
      this.nextStep();
    },
    pageChange() {
    },
    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
    },
    previousStep() {
      this.currentStep = this.currentStep - 1;
      this.showLeaveConfirmation = false;
    },
    nextStep() {
      this.currentStep = this.currentStep + 1;

      if (this.currentStep === 3) {
        this.showLeaveConfirmation = false;

        BojAPI.saveTicket(this.newTicket).then((response) => {
          this.responseTicket = response.data;
          this.$router.push({
            name: 'ticket',
            params: {id: this.responseTicket.id},
          });
        });
      } else {
        this.showLeaveConfirmation = true;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.showLeaveConfirmation) {
      const answer = window.confirm(this.$t('labels.close-ticket-without-saving'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    defaultQuery() {
      if (this.$store.getters.permissions.includes('ticket-access-office')) {
        return {
          query: {
            bool: {
              must: [
                {
                  match: {
                    'office.raw': this.user.user.member.local_office.office.label,
                  },
                },
              ],
            },
          },
        };
      } else {
        return {
          query: {
            bool: {
              'must_not': [
                {
                  match: {
                    'office.raw': 'Placeholder',
                  },
                },
              ],
            },
          },
        };
      }
    },
  },
};
</script>
